<template>
  <div>
    <label class="material-label">
      <slot />
    </label>
    <MDinput
      v-model="streetName"
      :required="false"
      :maxlength="64"
      :disabled="disabled"
      name="common.streetName"
      @input="onStreetNameChanged"
    >
      {{ $t('common.streetName') }}
    </MDinput>
    <input-errors :errors="streetNameErrors" />
    <MDinput
      v-model="number"
      :required="false"
      :maxlength="16"
      name="common.streetNumber"
      :disabled="disabled"
      @input="onNumberChanged"
    >
      {{ $t('common.streetNumber') }}
    </MDinput>
    <input-errors :errors="numberErrors" />
    <MDinput
      v-model="postalCode"
      :required="false"
      :maxlength="16"
      :disabled="disabled"
      name="common.postalCode"
      text-transform="uppercase"
      @input="onPostalCodeChanged"
    >
      {{ $t('common.postalCode') }}
    </MDinput>
    <input-errors :errors="postalCodeErrors" />
    <MDinput
      v-model="city"
      :required="false"
      :disabled="disabled"
      :maxlength="64"
      name="common.city"
      @input="onCityChanged"
    >
      {{ $t('common.city') }}
    </MDinput>
    <input-errors :errors="cityErrors" />
    <Select
      v-if="countries.length"
      v-model="countryID"
      :loading="countriesLoading"
      :required="false"
      :options="countries"
      :disabled="disabled"
      value-field="id"
      label-field="name"
      name="common.country"
      :placeholder="$t('common.country')"
      class="country-select"
      @input="onCountryChanged"
    ></Select>
  </div>
</template>

<script>
import InputErrors from '@/components/DSE/InputErrors';
import MDinput from '@/components/MDinput';
import Select from '@/components/DSE/Select';
import { validateOptionalAddress } from '@/utils/validate';

export default {
  name: 'AddressInput',
  components: {
    InputErrors,
    MDinput,
    Select
  },
  props: {
    value: {
      default() {
        return {};
      },
      type: [Object, String]
    },
    countries: {
      type: Array,
      default() {
        return null;
      }
    },
    countriesLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    complete: {
      type: Boolean,
      default: false
    },
    defaultCountryCode: String,
    clearDefaultOnSaveIfEmpty: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      streetName: '',
      number: '',
      postalCode: '',
      city: '',
      countryID: null,
      streetNameErrors: [],
      numberErrors: [],
      postalCodeErrors: [],
      cityErrors: []
    };
  },
  watch: {
    value(value) {
      this.streetName = (value && value.streetName) || '';
      this.number = (value && value.number) || '';
      this.postalCode = (value && value.postalCode) || '';
      this.city = (value && value.city) || '';
      this.countryID = (value && value.countryID) || null;

      this.streetNameErrors = [];
      this.numberErrors = [];
      this.postalCodeErrors = [];
      this.cityErrors = [];

      if (this.complete) {
        this.validate();
      }
    },
    countries(countries) {
      if (this.defaultCountryID || !this.defaultCountryCode) {
        return;
      }
      if (!this.isAddressEmpty()) {
        return;
      }
      const defaultCountry = countries.find(c => c.code === this.defaultCountryCode);
      if (defaultCountry && defaultCountry.id) {
        this.setDefaultCountryID(defaultCountry.id);
      }
    }
  },
  mounted() {
    this.$emit('presave', this.defaultValueRemoverHook);
    this.$emit('postsave', this.defaultValueRecoveryHook);
  },
  methods: {
    isAddressEmpty() {
      return (
        !this.streetName &&
        !this.number &&
        !this.postalCode &&
        !this.city &&
        (!this.countryID || this.countryID === this.defaultCountryID)
      );
    },
    defaultValueRemoverHook() {
      this.defaultCountryCleared = false;
      if (!this.clearDefaultOnSaveIfEmpty) {
        return;
      }
      if (!this.defaultCountryID) {
        return;
      }
      if (!this.isAddressEmpty()) {
        return;
      }
      if (this.countryID === this.defaultCountryID) {
        this.defaultCountryCleared = true;
        this.$emit('input', null);
      }
    },
    defaultValueRecoveryHook() {
      if (!this.defaultCountryCleared) {
        return;
      }
      this.setDefaultCountryID(this.defaultCountryID);
    },
    setDefaultCountryID(id) {
      this.defaultCountryID = id;
      this.onCountryChanged(this.defaultCountryID);
      this.$nextTick(_ => {
        this.$emit('init', this.value);
      });
    },
    validate() {
      const isValidAddress = validateOptionalAddress(this.value);

      if (!this.streetName && !isValidAddress) {
        this.streetNameErrors = [{ message: this.$i18n.t('validation.incompleteOptionalAddress') }];
      }
      if (!this.number && !isValidAddress) {
        this.numberErrors = [{ message: this.$i18n.t('validation.incompleteOptionalAddress') }];
      }
      if (!this.postalCode && !isValidAddress) {
        this.postalCodeErrors = [{ message: this.$i18n.t('validation.incompleteOptionalAddress') }];
      }
      if (!this.city && !isValidAddress) {
        this.cityErrors = [{ message: this.$i18n.t('validation.incompleteOptionalAddress') }];
      }
    },
    copyValues() {
      if (!this.value) {
        return {};
      }
      return Object.keys(this.value).reduce((newValues, key) => {
        newValues[key] = this.value[key];
        return newValues;
      }, {});
    },
    onStreetNameChanged(streetName) {
      const address = {
        ...this.copyValues(),
        streetName: streetName
      };
      this.$emit('input', address);
    },
    onNumberChanged(number) {
      const address = {
        ...this.copyValues(),
        number: number
      };
      this.$emit('input', address);
    },
    onPostalCodeChanged(postalCode) {
      const address = {
        ...this.copyValues(),
        postalCode: postalCode
      };
      this.$emit('input', address);
    },
    onCityChanged(city) {
      const address = {
        ...this.copyValues(),
        city: city
      };
      this.$emit('input', address);
    },
    onCountryChanged(countryID) {
      const address = {
        ...this.copyValues(),
        countryID: countryID
      };
      this.$emit('input', address);
    }
  }
};
</script>
<style lang="scss" scoped>
.country-select {
  margin-top: 5px;
}
</style>
