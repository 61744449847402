<template>
  <embedded-crud-table
    :entity="entity"
    :entity-store="entityStore"
    :columns="columns"
    :generate-summary="generateSummary"
    :definition="definition"
    :caption="caption"
    :disabled="disabled"
    :parent-id-filter-field="parentIdFilterField"
    :open-in-fullscreen="openInFullscreen"
    :required="required"
    :create-item-from-form-data="createItemFromFormData"
    :create-form-data-from-item="createFormDataFromItem"
    :permission-code="permissionCode"
    :parent-i-d="parentID"
  />
</template>

<script>
import EmbeddedCrudTable from '@/components/crud/EmbeddedCrudTable';
import formMixin from './form-mixin';
import { booleanColumnFilter, booleanColumnFormatter } from '../../../utils/filters';

export default {
  components: {
    EmbeddedCrudTable
  },
  mixins: [formMixin],
  props: {
    caption: String,
    parentIdFilterField: {
      type: String,
      required: true
    },
    permissionCode: String,
    disabled: {
      default: false,
      type: Boolean
    },
    openInFullscreen: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    },
    parentID: Number
  },
  data() {
    return {
      columns: [
        { field: 'name' },
        { field: 'code' },
        { field: 'vehicleTypeName', label: 'common.vehicleType' },
        {
          field: 'onlineYN',
          label: 'common.onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ],
      type: Array
    };
  },
  methods: {
    generateSummary(row) {
      return `${row.name}`;
    }
  }
};
</script>

<style lang="scss" scoped></style>
