import MDinput from '@/components/MDinput';
import { validatorRequire, validatorEmail } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      props: {
        maxlength: 64
      },
      rules: [{ validator: validatorRequire }]
    },
    jobFunction: {
      type: MDinput,
      props: {
        maxlength: 64
      }
    },
    department: {
      type: MDinput,
      props: {
        maxlength: 64
      }
    },
    email: {
      type: MDinput,
      props: {
        type: 'email',
        maxlength: 64
      },
      rules: [{ validator: validatorEmail }]
    },
    fixedPhone: {
      type: MDinput,
      props: {
        type: 'tel'
      }
    },
    mobilePhone: {
      type: MDinput,
      props: {
        type: 'tel'
      }
    },
    description: {
      type: MDinput,
      props: {
        maxlength: 255
      }
    }
  };
}
export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
