import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';
import i18n from '@/i18n';
export default {
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  mixins: [baseCrudMixin],
  data: () => ({
    entity: 'Printer',
    returnTo: 'Printers'
  }),
  methods: {
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem,
    createItemFromFormData,
    async printTestPage(printerId) {
      const form = this.$store.getters['editor/flattenedForm'];
      const printCommand = {
        restaurantID: +this.$route.params.id,
        printerID: printerId || +form.id
      };
      await this.$store.dispatch('printer/printTestPage', printCommand);
      this.$store.dispatch('notify/success', { message: this.$i18n.t('printer.testPagePrinted') });
    },
    onOpenContextMenu(row, column, event) {
      const contextMenuOptions = [];
      contextMenuOptions.push({ name: i18n.t('printer.printTestPage'), type: 'printTestPage' });
      return contextMenuOptions;
    },
    async onContextMenuOptionClicked(item, option) {
      if (option.type === 'printTestPage') {
        await this.printTestPage(item.id);
      }
    }
  }
};
