import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequireNumber } from '@/utils/validators';
import DateTimePicker from '@/components/DSE/DateTimePicker';
import Select from '@/components/DSE/Select';
import FormButton from '@/components/DSE/FormButton';
import Switch from '@/components/DSE/Switch';

export function getFormDefinition(vue) {
  return {
    printButton: {
      type: FormButton,
      hideOnCreate: true,
      props: {
        caption: 'common.emptyString',
        buttonText: 'printer.printTestPage',
        onclick: vue.printTestPage,
        icon: 'el-icon-printer'
      }
    },
    name: {
      type: MDinput,
      props: {
        maxlength: 64
      },
      rules: [{ validator: validatorRequire }]
    },
    printProvider: {
      type: Select,
      props: {
        caption: 'printer.printProvider',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('printProvider')
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    typeOption: {
      type: Select,
      props: {
        caption: 'printer.typeOption',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('printerType')
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    statusOption: {
      type: Select,
      hideOnCreate: true,
      props: {
        caption: 'printer.statusOption',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('printerStatus'),
        disabled: true
      }
    },
    statusText: {
      type: MDinput,
      hideOnCreate: true,
      props: {
        caption: 'printer.statusText',
        maxlength: 255,
        disabled: true
      }
    },
    statusReceivedAt: {
      type: DateTimePicker,
      hideOnCreate: true,
      props: {
        caption: 'printer.statusReceivedAt',
        disabled: true
      }
    },
    expectStatusYN: {
      type: Switch,
      defaultValue: true,
      props: {
        caption: 'printer.expectStatus',
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    }
  };
}
export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  const item = { ...form };
  delete item.printButton;
  return item;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
