import MDinput from '@/components/MDinput';
import Switch from '@/components/DSE/Switch';
import NumericInput from '@/components/DSE/NumericInput';
import Select from '@/components/DSE/Select';
import { validatorRequire } from '@/utils/validators';
import { DeviceType } from '@/utils/constants';

export function getFormDefinition(vue) {
  return {
    ecrID: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'pinDevice.ecrID',
        type: 'text',
        maxlength: 32,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    deviceTypeOption: {
      type: Select,
      defaultValue: DeviceType.ECR,
      props: {
        caption: 'pinDevice.deviceType',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('pinDeviceType'),
        required: true
      }
    },
    terminalWakeupIpAddress: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'pinDevice.wakeupIpAddress',
        type: 'text',
        maxlength: 32,
        readonly: true
      }
    },
    terminalWakeupIpPort: {
      type: NumericInput,
      defaultValue: null,
      props: {
        caption: 'pinDevice.wakeupIpPort',
        precision: 0,
        showThousandSeparator: false,
        readonly: true
      }
    },
    status: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'pinDevice.status',
        readonly: true
      }
    },
    transactionID: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'pinDevice.transactionID',
        readonly: true
      }
    },
    onlineYN: {
      type: Switch,
      defaultValue: false,
      props: {
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    }
  };
}
export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
