import MDinput from '@/components/MDinput';
import AddressInput from '@/components/DSE/AddressInput';
import {
  validatorConditionalRequired,
  validatorConditionalRequiredNumber,
  validatorEmail,
  validatorRequire,
  validatorRequireNumber,
  validatorConditionalRequiredUrl
} from '@/utils/validators';
import CrudContactPerson from '../components/CrudContactPerson';
import Switch from '@/components/DSE/Switch';
import InputNumber from '@/components/DSE/InputNumber';
import Select from '@/components/DSE/Select';
import CrudPrinter from '../components/CrudPrinter';
import MultiLanguageInput from '@/components/DSE/MultiLanguageInput';
import CrudEmployee from '../employees/CrudEmployee';
import CrudVehicle from '../vehicles/CrudVehicle';
import TimePicker from '@/components/DSE/TimePicker';
import PostcodeRestaurantEditor from './PostcodeRestaurantEditor';
import { DayOfWeek, DeliveryMethodOption } from '../../../utils/constants';
import CrudPinDevice from '../components/CrudPinDevice';
import i18n from '@/i18n';
import OpeningHoursEditor from './OpeningHoursEditor';

export function getFormDefinition(vue) {
  return {
    type: 'tab',
    saveButtons: [true, false, false, false, false, false, false, false, false, false, false], // Page, tab 1, tab 2, ...
    showFormLabels: [true, true, true, false, false, false, false, false, true, false, true], // Tab 1, tab 2, ....
    labels: [
      'common.tabGeneral',
      'restaurant.tabCashDesk',
      'restaurant.website',
      'common.employees',
      'restaurant.tabContactPersons',
      'common.printers',
      'restaurant.pinDevices',
      'common.vehicles',
      'restaurant.openingHours',
      'restaurant.postcodes',
      'restaurant.newPos'
    ],
    headerTemplate: (str, form) => `
    <h3>
    ${form.name || ''}
    </h3>
    `,
    groups: [
      {
        // tab 1
        name: {
          type: MDinput,
          props: {
            maxlength: 64,
            required: true
          },
          rules: [
            {
              validator: validatorRequire
            }
          ]
        },
        code: {
          type: MDinput,
          props: {
            maxlength: 32,
            caption: 'common.code'
          },
          dynamicProps: form => ({
            readonly: vue.isEdit
          }),
          rules: [
            {
              validator: validatorRequire
            }
          ]
        },
        priceCategoryID: {
          type: Select,
          defaultValue: null,
          props: {
            caption: 'common.priceCategory',
            required: true,
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['priceCategory/isLoading'],
            options: vue.$store.getters['priceCategory/loadedItems']
          },
          rules: [
            {
              validator: validatorRequireNumber
            }
          ]
        },
        onlineYN: {
          type: Switch,
          defaultValue: false,
          props: {
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        address: {
          type: AddressInput,
          props: {
            countries: vue.$store.getters['country/loadedItems'],
            countriesLoading: vue.$store.getters['country/isLoading']
          }
        },
        billingAddress: {
          type: AddressInput,
          props: {
            countries: vue.$store.getters['country/loadedItems'],
            countriesLoading: vue.$store.getters['country/isLoading']
          }
        },
        email: {
          type: MDinput,
          props: {
            type: 'email',
            maxlength: 64
          },
          dynamicRules: form => [
            { validator: validatorEmail },
            { validator: validatorConditionalRequired(form.onlineYN) }
          ]
        },
        phone: {
          type: MDinput,
          props: {
            type: 'tel'
          },
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.onlineYN) }]
        },
        latitude: {
          type: MDinput,
          props: {
            type: 'number',
            min: -90,
            max: 90,
            precision: 7,
            caption: 'common.latitude'
          },
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.onlineYN) }]
        },
        longitude: {
          type: MDinput,
          props: {
            type: 'number',
            min: -180,
            max: 180,
            precision: 7,
            caption: 'common.longitude'
          },
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.onlineYN) }]
        },
        robexCustomerNumber: {
          type: MDinput,
          props: {
            maxlength: 8,
            caption: 'restaurant.robexCustomerNumber'
          }
        },
        chamberOfCommerceNumber: {
          type: MDinput,
          props: {
            maxlength: 16,
            caption: 'common.chamberOfCommerceNumber'
          }
        },
        ibanNumber: {
          type: MDinput,
          props: {
            maxlength: 32,
            caption: 'common.ibanNumber'
          }
        },
        vatID: {
          type: MDinput,
          props: {
            maxlength: 32,
            caption: 'common.vatNumber'
          }
        },
        mollieKey: {
          type: MDinput,
          props: {
            maxlength: 64,
            caption: 'common.mollieKey'
          }
        },
        toGoConceptYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'common.toGo',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        takeOutAndDeliveryConceptYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'common.takeOutAndDeliveryConcept',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        takeOutPossibleYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'restaurant.takeOutPossible',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        isTSGCustomerYN: {
          type: Switch,
          defaultValue: true,
          props: {
            caption: 'restaurant.isTSGCustomerYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        insocialCustomerID: {
          type: MDinput,
          props: {
            maxlength: 8,
            caption: 'restaurant.insocialCustomerID'
          }
        },
        defaultDeliveryMethod: {
          type: Select,
          defaultValue: DeliveryMethodOption.Delivery,
          props: {
            caption: 'restaurant.defaultDeliveryMethod',
            valueField: 'code',
            labelField: 'description',
            loading: vue.$store.getters['option/isLoading'],
            options: vue.$store.getters['option/loadedOptions']('deliveryMethod'),
            required: true
          }
        },
        addressCheckCompletedYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'restaurant.addressCheckCompletedYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        openingSoonYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'restaurant.openingSoonYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        testRestaurantYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'restaurant.testRestaurantYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        partnershipID: {
          type: Select,
          defaultValue: null,
          props: {
            caption: 'common.partnership',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['partnership/isLoading'],
            options: vue.$store.getters['partnership/loadedItems']
          },
          dynamicRules: form => [
            { validator: validatorSpecial(form.useShiftBaseYN, vue.$store.getters['partnership/loadedItems']) }
          ]
        },
        nameOnTicket: {
          type: MDinput,
          props: {
            maxlength: 64,
            caption: 'restaurant.nameOnTicket'
          }
        }
      },
      // Tab cashdesk
      {
        deliveryTime: {
          type: InputNumber,
          defaultValue: 45,
          props: {
            caption: 'restaurant.deliveryTime',
            step: 15,
            precision: 0
          }
        },
        takeOutDiscountPercentage: {
          type: InputNumber,
          defaultValue: undefined,
          props: {
            caption: 'restaurant.takeOutDiscountPercentage',
            step: 1,
            precision: 0
          },
          dynamicRules: form => [{ validator: validatorConditionalRequiredNumber(form.onlineYN) }]
        },
        cashdeskDomain: {
          type: MDinput,
          props: {
            maxlength: 64,
            caption: 'restaurant.cashdeskDomain'
          },
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.onlineYN) }]
        },
        queuePrinterClientCode: {
          type: MDinput,
          props: {
            maxlength: 32,
            caption: 'restaurant.queuePrinterClientCode'
          }
        },
        ticketHeader: {
          type: MDinput,
          props: {
            maxlength: 255,
            caption: 'restaurant.ticketHeader'
          }
        },
        ticketFooter: {
          type: MDinput,
          props: {
            maxlength: 255,
            caption: 'restaurant.ticketFooter'
          }
        },
        numberOfTickets: {
          type: InputNumber,
          defaultValue: 2,
          props: {
            caption: 'restaurant.numberOfTickets',
            step: 1,
            precision: 0
          }
        },
        mobilePinYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'restaurant.mobilePin',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        idealYN: {
          type: Switch,
          defaultValue: true,
          props: {
            caption: 'restaurant.idealYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        giftcardYN: {
          type: Switch,
          defaultValue: true,
          props: {
            caption: 'restaurant.giftcardYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        cashPaymentYN: {
          type: Switch,
          defaultValue: true,
          props: {
            caption: 'restaurant.cashPaymentYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        cashdeskDirectPrintYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'restaurant.cashdeskDirectPrint',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        showDeliveryScreenYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'restaurant.showDeliveryScreen',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        showDayRevenueYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'restaurant.showDayRevenueYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        useShiftBaseYN: {
          type: Switch,
          defaultValue: true,
          props: {
            caption: 'common.useShiftBaseYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        }
      },
      // Tab Website
      {
        detailPageBlock1: {
          type: MultiLanguageInput,
          props: {
            caption: 'restaurant.detailPageBlock1',
            type: 'textarea'
          }
        },
        detailPageBlock2: {
          type: MultiLanguageInput,
          props: {
            caption: 'restaurant.detailPageBlock2',
            type: 'textarea'
          }
        }
      },
      // Tab Employees
      {
        employees: {
          type: CrudEmployee,
          defaultValue: [],
          props: {
            caption: 'common.emptyString',
            openInFullscreen: false,
            parentIdFilterField: 'restaurantID',
            permissionCode: 'Restaurant'
          }
        }
      },
      // Tab Contact persons
      {
        contactPersons: {
          type: CrudContactPerson,
          defaultValue: [],
          props: {
            caption: 'common.emptyString',
            openInFullscreen: false,
            parentIdFilterField: 'restaurantID',
            permissionCode: 'Restaurant'
          }
        }
      },
      // Tab Printers
      {
        printers: {
          type: CrudPrinter,
          defaultValue: [],
          props: {
            caption: 'common.emptyString',
            openInFullscreen: false,
            parentIdFilterField: 'restaurantID',
            permissionCode: 'Printer'
          }
        }
      },
      // Tab pin devices
      {
        pinDevices: {
          type: CrudPinDevice,
          props: {
            caption: 'common.emptyString',
            parentIdFilterField: 'restaurantID'
          }
        }
      },
      // Tab Voertuigen
      {
        vehicle: {
          type: CrudVehicle,
          defaultValue: [],
          props: {
            caption: 'common.emptyString',
            openInFullscreen: false,
            parentIdFilterField: 'restaurantID',
            permissionCode: 'Vehicle'
          }
        }
      },
      // Tab Opening hours
      {
        openingTime: {
          type: TimePicker,
          defaultValue: null,
          props: {
            maxlength: 5,
            caption: 'restaurant.openingTime'
          },
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.onlineYN) }]
        },
        closingTime: {
          type: TimePicker,
          defaultValue: null,
          props: {
            maxlength: 5,
            caption: 'restaurant.closingTime'
          },
          dynamicRules: form => [{ validator: validatorConditionalRequired(form.onlineYN) }]
        },
        dailyOpeningHoursForDelivery: {
          type: OpeningHoursEditor,
          defaultValue: null,
          props: {
            caption: 'restaurant.openingTimeForDelivery'
          },
          rules: [
            {
              validator: validatorOpeningHours
            }
          ]
        },
        dailyOpeningHoursForPickup: {
          type: OpeningHoursEditor,
          defaultValue: null,
          props: {
            caption: 'restaurant.openingTimeForPickup'
          },
          rules: [
            {
              validator: validatorOpeningHours
            }
          ]
        },
        openingTimesStatement: {
          type: MDinput,
          props: {
            maxlength: 255,
            caption: 'restaurant.openingTimesStatement'
          }
        },
        deliveryCostsLimit: {
          type: MDinput,
          defaultValue: 0,
          props: {
            type: 'currency',
            caption: 'restaurant.deliveryCostsLimit'
          },
          dynamicRules: form => [{ validator: validatorConditionalRequiredNumber(form.onlineYN) }]
        },
        minimumOrderValue: {
          type: MDinput,
          defaultValue: 0,
          props: {
            type: 'currency',
            caption: 'restaurant.minimumOrderValue'
          },
          dynamicRules: form => [{ validator: validatorConditionalRequiredNumber(form.onlineYN) }]
        }
      }, // Tab postcodes
      {
        postcodesOpeningHours: {
          type: PostcodeRestaurantEditor,
          props: {
            caption: 'common.emptyString',
            restaurantID: +vue.$route.params.id
          },
          dynamicProps: form => ({
            countryCode: form.countryCode
          }),
          rules: [
            {
              validator: validatorPostcodeOpeningHours
            }
          ]
        }
      }, // Tab switch to new PoS
      {
        switchedToNewPosYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'restaurant.useNewPosYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        redirectSearchUrl: {
          type: MDinput,
          props: {
            maxlength: 255,
            caption: 'restaurant.redirectSearchUrl'
          },
          dynamicRules: form => [{ validator: validatorConditionalRequiredUrl(form.switchedToNewPosYN) }]
        },
        redirectOrderUrl: {
          type: MDinput,
          props: {
            maxlength: 255,
            caption: 'restaurant.redirectOrderUrl'
          },
          dynamicRules: form => [{ validator: validatorConditionalRequiredUrl(form.switchedToNewPosYN) }]
        }
      }
    ]
  };
}

const layoutTabGeneral = {
  named: true,
  sections: [
    {
      width: 33,
      fields: [
        'name',
        'code',
        'priceCategoryID',
        'robexCustomerNumber',
        'chamberOfCommerceNumber',
        'vatID',
        'ibanNumber',
        'mollieKey',
        'partnershipID'
      ]
    },
    {
      width: 33,
      fields: ['email', 'phone', 'nameOnTicket', 'address', 'billingAddress']
    },
    {
      width: 33,
      fields: [
        'latitude',
        'longitude',
        'defaultDeliveryMethod',
        'insocialCustomerID',
        'toGoConceptYN',
        'takeOutAndDeliveryConceptYN',
        'takeOutPossibleYN',
        'isTSGCustomerYN',
        'addressCheckCompletedYN',
        'openingSoonYN',
        'useShiftBaseYN',
        'testRestaurantYN',
        'onlineYN'
      ]
    }
  ]
};

const layoutFullWidth = {
  sections: [
    {
      width: 100
    }
  ]
};

export function getCreateLayoutDefinition(vue) {
  return {
    groups: [layoutTabGeneral, layoutFullWidth, layoutFullWidth]
  };
}

export function getEditLayoutDefinition(vue) {
  return {
    groups: [
      layoutTabGeneral,
      layoutFullWidth,
      layoutFullWidth,
      layoutFullWidth,
      layoutFullWidth,
      layoutFullWidth,
      layoutFullWidth,
      layoutFullWidth,
      layoutFullWidth,
      layoutFullWidth,
      layoutFullWidth
    ]
  };
}

export function createItemFromFormData(vue, form) {
  if (vue.isEdit) {
    const item = {
      ...form,
      postcodes: form.postcodesOpeningHours
        .filter(e => !!e.postcode)
        .map(e => {
          return { ...e, postcode: e.postcode.toString() };
        }),
      dailyOpeningHoursForPickup: form.dailyOpeningHoursForPickup[0].dailyOpeningHours,
      dailyOpeningHoursForDelivery: form.dailyOpeningHoursForDelivery[0].dailyOpeningHours
    };
    delete item.postcodesOpeningHours;
    return item;
  }
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item,
    postcodesOpeningHours: item.postcodes
      .map(e => {
        return {
          ...e,
          dailyOpeningHours: mapOpeningHoursToForm(e.dailyOpeningHours)
        };
      })
      .sort((a, b) => {
        return +a.postcode < +b.postcode ? -1 : 1;
      }),
    dailyOpeningHoursForPickup: [{ dailyOpeningHours: mapOpeningHoursToForm(item.dailyOpeningHoursForPickup) }],
    dailyOpeningHoursForDelivery: [{ dailyOpeningHours: mapOpeningHoursToForm(item.dailyOpeningHoursForDelivery) }]
  };
  delete form.postcodes;
  return form;
}

function mapOpeningHoursToForm(dailyOpeningHours) {
  for (const [dayName, dayNumber] of Object.entries(DayOfWeek)) {
    const openingHours = dailyOpeningHours.find(e => e.dayOfWeek === dayNumber);
    if (!openingHours) {
      dailyOpeningHours.push({
        dayOfWeek: dayNumber,
        dayOfWeekName: dayName,
        openingTime: null,
        closingTime: null
      });
    } else {
      openingHours.dayOfWeekName = dayName;
    }
  }
  return dailyOpeningHours.sort((a, b) => {
    return a.dayOfWeek < b.dayOfWeek ? -1 : 1;
  });
}

function validatorPostcodeOpeningHours(rule, value, callback) {
  let hasError = false;
  if (!value) {
    callback();
  } else {
    value.forEach(row => {
      if (
        row.postcodeUniquenessViolation ||
        row.postcodeFormatError ||
        row.dailyOpeningHoursError_0 ||
        row.dailyOpeningHoursError_1 ||
        row.dailyOpeningHoursError_2 ||
        row.dailyOpeningHoursError_3 ||
        row.dailyOpeningHoursError_4 ||
        row.dailyOpeningHoursError_5 ||
        row.dailyOpeningHoursError_6 ||
        row.deliveryChargeError ||
        row.minimumOrderValueError ||
        row.freeDeliveryThresholdError
      ) {
        hasError = true;
      }
    });
    if (hasError) {
      callback(new Error(i18n.t('restaurant.postcodeOpeningHoursValidationError')));
    } else {
      callback();
    }
  }
}

function validatorOpeningHours(rule, value, callback) {
  let hasError = false;
  if (!value) {
    callback();
  } else {
    value.forEach(row => {
      if (
        row.dailyOpeningHoursError_0 ||
        row.dailyOpeningHoursError_1 ||
        row.dailyOpeningHoursError_2 ||
        row.dailyOpeningHoursError_3 ||
        row.dailyOpeningHoursError_4 ||
        row.dailyOpeningHoursError_5 ||
        row.dailyOpeningHoursError_6
      ) {
        hasError = true;
      }
    });
    if (hasError) {
      callback(new Error(i18n.t('restaurant.openingHoursValidationError')));
    } else {
      callback();
    }
  }
}

const validatorSpecial = (conditionalValue, options, errorMessageRequired) => (rule, value, callback) => {
  if (!conditionalValue) {
    callback();
  }
  if (!value || value.length === 0) {
    callback(new Error(i18n.t(errorMessageRequired || 'validation.required')));
  }
  if (value) {
    const result = options.find(partnership => partnership.id === value);
    if (result !== undefined && !result.useShiftBaseYN) {
      callback(new Error(i18n.t('restaurant.partnershipShiftbase')));
    }
  }
  callback();
};
