import { getEmployeeFormDefinition } from './form-elements';

export function getFormDefinition(vue) {
  return getEmployeeFormDefinition(vue);
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
